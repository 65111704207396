import { ChangeEvent } from "react";
import { Button } from "components";
import Select, { Option } from "@ingka/select";
import prev from "@ingka/ssr-icon/paths/chevron-left";
import next from "@ingka/ssr-icon/paths/chevron-right";
import { Box, Stack, Text } from "components";
import { t, T } from "i18n";
import { TranslationKeys } from "i18n/translationKeys";

export type OpportunitiesPaginationProps = {
  page: number;
  pageSize: number;
  numOfPages: number;
  onPageChange: (limit: number) => void;
  onPageSizeChange: (offset: number) => void;
  labelKey: TranslationKeys;
};

export const OpportunitiesPagination = ({
  page,
  pageSize,
  numOfPages,
  onPageChange,
  onPageSizeChange,
  labelKey,
}: OpportunitiesPaginationProps) => {
  const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onPageSizeChange(Number(event.currentTarget.value));
  };

  return (
    <Box
      justifyContent="space-between"
      borderColor="neutralGrey100"
      border={[1, 0, 1, 0]}
      padding={[24, 0]}
    >
      <Stack alignItems="center">
        <Text small>
          <T id="opportunities.pagination.label" />
        </Text>
        <Select
          hintText=""
          id="OpportunitiesPaginationSelect"
          label=""
          value={pageSize}
          onChange={handlePageSizeChange}
        >
          <Option value="10" name="10" />
          <Option value="25" name="25" />
          <Option value="50" name="50" />
        </Select>

        <Text small>
          <T id={labelKey} />
        </Text>
      </Stack>

      <Stack alignItems="center" dontGrow>
        <Button
          small
          text={t("opportunities.pagination.first.button")}
          onClick={() => onPageChange(0)}
          disabled={page - 1 < 0}
        />
        <Button
          small
          iconOnly
          ssrIcon={prev}
          onClick={() => onPageChange(page - 1)}
          disabled={page - 1 < 0}
        />
        <Text small>
          <T
            id="opportunities.table.pagination.state"
            values={{
              current: page + 1,
              total: numOfPages + 1,
            }}
          />
        </Text>
        <Button
          small
          iconOnly
          ssrIcon={next}
          onClick={() => onPageChange(page + 1)}
          disabled={page + 1 > numOfPages}
        />
        <Button
          small
          text={t("opportunities.pagination.last.button")}
          onClick={() => onPageChange(numOfPages)}
          disabled={page + 1 > numOfPages}
        />
      </Stack>
    </Box>
  );
};
