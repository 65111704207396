import { ReactNode, Children, isValidElement, cloneElement } from "react";
import { Icon } from "../Icon.component";
import { Popover } from "./Popover.component";

interface Props {
  closePopover?(): void;
  children?: ReactNode;
}
export const PopoverMenu = ({ children, closePopover }: Props) => {
  return (
    <Popover trigger={<Icon icon="OverflowIcon" />}>
      {Children.map(children, (child) =>
        isValidElement(child)
          ? cloneElement(child, {
              closePopover,
            })
          : child
      )}
    </Popover>
  );
};

PopoverMenu.displayName = "PopoverMenu";
