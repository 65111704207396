import { environment } from "../feature-flags/environment";

/**
 * The markets where this app is currently live
 */
export const activeMarkets = [
  "AU",
  "SE",
  "FI",
  "CA",
  "RO",
  "RS",
  "HR",
  "SI",
  "DK",
  "PL",
  "PT",
  "AT",
  "CH",
  "IT",
  "JP",
  "IN",
] as const;

export const activeCTEMarkets = [
  "AU",
  "SE",
  "FI",
  "CA",
  "RO",
  "RS",
  "HR",
  "SI",
  "DK",
  "PL",
  "PT",
  "CH",
  "AT",
  "IT",
  "IN",
  "JP",
  "ES",
  "NO",
  "DE",
  "FR",
] as const;

/**
 * A market where this app is currently live
 */
export type Market = typeof activeMarkets[number];

export type CTEMarket = typeof activeCTEMarkets[number];

/**
 * The fallback market to use if the user does not belong to any active market
 */
export const defaultMarket = "SE";

/**
 * Check if a market is one of our active markets
 */
export const isActiveMarket = (
  market: string
): market is Market | CTEMarket => {
  return environment.isProd
    ? activeMarkets.includes(market as Market)
    : activeCTEMarkets.includes(market as CTEMarket);
};
